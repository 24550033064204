import React, { ReactNode, ReactElement } from 'react'

type NiftyTableProps = {
  dataBody: ReactNode
  headers: Array<string>
}

const NiftyTable = ({ dataBody, headers }: NiftyTableProps): ReactElement => {
  return (
    <div className='table-responsive'>
      <table className='table table-striped'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{dataBody}</tbody>
      </table>
    </div>
  )
}

export default NiftyTable
