import Api from 'api/Api'
import { IActivityType } from 'models/ActivityType'
const resource = '/activity_types'

type ActivityTypeSelect = {
  value: string
  label: string
}

interface ActivityTypeData {
  activityTypes: Array<ActivityTypeSelect>
}

export default class ActivityTypeService {
  static async list(): Promise<ActivityTypeData> {
    const { data } = await Api.get(`${resource}`)
    const activityTypes = data.activity_types.map((item: IActivityType) => {
      return { value: item.code, label: item.description }
    })
    activityTypes.unshift({ value: '', label: 'Todas' })
    return { activityTypes }
  }
}
