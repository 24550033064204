import React, { useEffect, useState, ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolder,
  faFolderOpen,
  faFileImage,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { notify } from 'components/Toast/Toast'
import Loader from 'react-loader-spinner'
import LibraryService from 'services/LibraryService'
import { IFile } from 'models/Book'
import { Modal, Row, Col, Button } from 'react-bootstrap'

const Library = (): ReactElement => {
  const [loading, setLoading] = useState(false)
  const [folder, setFolder] = useState(new Array<IFile>(0))
  const [currentFolder, setCurrentFolder] = useState(new Array<string>(0))
  const [showModal, setShowModal] = useState(false)

  const [buckets, setBuckets] = useState(new Array<string>(0))
  const [currentBucket, setCurrentBucket] = useState('')

  const [currentFile, setCurrentFile] = useState<IFile | null>(null)
  const [indexFile, setIndexFile] = useState(-1)

  //pagination
  const [marker, setMarker] = useState('')

  useEffect(() => {
    getBuckets()
  }, [])

  useEffect(() => {
    if (currentBucket !== '') getFolders()
  }, [currentBucket, currentFolder])

  const getBuckets = async () => {
    try {
      const bucketsName = await LibraryService.getBuckets()
      setBuckets(bucketsName)
    } catch (e) {
      console.log(e)
      if (e.error) notify('error', e.error)
    }
  }

  const getFolders = async (loadMore = false) => {
    setLoading(true)
    try {
      const { files, nextMarker } = await LibraryService.fileManager(
        currentBucket,
        currentFolder.join('/'),
        loadMore ? marker : '',
      )
      if (loadMore) setFolder(currentFiles => currentFiles.concat(files))
      else setFolder(files)
      setMarker(nextMarker)
    } catch (e) {
      console.log('Library Error: ', e)
      if (e.error) notify('error', e.error)
    }
    setLoading(false)
  }

  const getImage = (name: string) => {
    const indexFile = folder.findIndex(item => item.name === name)
    if (indexFile > -1) {
      changeCurrentFile(indexFile)
      setShowModal(true)
    }
  }

  const handlePrev = () => {
    if (indexFile > 0) {
      changeCurrentFile(indexFile - 1)
    }
  }

  const handleNext = () => {
    if (indexFile < folder.length) {
      changeCurrentFile(indexFile + 1)
    }
    if (indexFile === folder.length - 1) {
      if (marker) {
        getFolders(true)
        changeCurrentFile(indexFile + 1)
      }
    }
  }

  const changeCurrentFile = (index: number) => {
    const current = folder[index]
    if (current) {
      setCurrentFile(current)
      setIndexFile(index)
    }
  }

  const changeCurrentBucket = (name: string) => {
    setCurrentBucket(name)
    setCurrentFile(null)
    setFolder([])
    setCurrentFolder([])
  }

  const changeFolder = (type: string, name: string) => {
    if (type === 'Folder') setCurrentFolder([...currentFolder, name])
    if (type === 'File') getImage(name)
  }

  const backToPosition = (index: number) => {
    if (index + 1 !== currentFolder.length) {
      const newCurrentFolder = currentFolder.slice(0, index + 1)
      setCurrentFolder(newCurrentFolder)
    }
  }

  const back = () => {
    const newCurrentFolder = [...currentFolder]
    newCurrentFolder.pop()
    setCurrentFolder(newCurrentFolder)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <div className='panel'>
      <div className='panel-body'>
        <div className='pad-all file-manager'>
          <div className='fixed-fluid'>
            <div className='fixed-sm-200 pull-sm-left file-sidebar'>
              <p className='pad-hor mar-top text-main text-bold text-sm text-uppercase'>Biblioteca</p>
              <div className='list-group bg-trans pad-btm bord-btm'>
                {buckets.map((bucket, key) => (
                  <a
                    key={key}
                    onClick={() => changeCurrentBucket(bucket)}
                    className='list-group-item text-bold file-details'
                  >
                    <span className='text-main'>
                      <i className='icon-lg icon-fw'>
                        <FontAwesomeIcon icon={faFolderOpen} />
                      </i>
                      {bucket}
                    </span>
                  </a>
                ))}
              </div>
            </div>
            <div className='fluid file-panel'>
              <div className='bord-btm pad-ver'>
                <ol className='breadcrumb'>
                  {currentFolder.map((folder, key) => {
                    const active = key === currentFolder.length - 1
                    if (folder !== '') {
                      return (
                        <li key={key} className={active ? 'active' : ''}>
                          <a className='file-details' onClick={() => backToPosition(key)}>
                            {folder}
                          </a>
                        </li>
                      )
                    }
                    return (
                      <li key={key} className={active ? 'active' : ''}>
                        <a className='file-details' onClick={() => backToPosition(key)}>
                          Documentos
                        </a>
                      </li>
                    )
                  })}
                </ol>
              </div>
              <div className='nano' style={{ minHeight: '500px' }}>
                <div className='nano-content'>
                  <ul id='demo-mail-list' className='file-list'>
                    {currentFolder.length > 1 && (
                      <li>
                        <a className='file-details' onClick={back}>
                          <div className='media-block'>
                            <div className='media-left'>
                              <i>
                                <FontAwesomeIcon icon={faFolder} />
                              </i>
                            </div>
                            <div className='media-body'>
                              <p className='file-name single-line'>...</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}

                    {folder.map((folder, key) => (
                      <li key={key}>
                        <a onClick={() => changeFolder(folder.type, folder.name)} className='file-details'>
                          <div className='media-block'>
                            <div className='media-left'>
                              <i>
                                <FontAwesomeIcon icon={folder.type === 'Folder' ? faFolder : faFileImage} />
                              </i>
                            </div>
                            <div className='media-body'>
                              <p className='file-name'>{folder.name}</p>
                              <small>{folder.size}</small>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                    {marker !== '' && (
                      <li>
                        <a className='file-details' onClick={() => getFolders(true)}>
                          <div className='media-block text-center'>
                            <div className='media-body'>
                              <p className='text-bold text-primary'>
                                <i className='icon-lg icon-fw'>
                                  <FontAwesomeIcon icon={faPlusCircle} size='lg' />
                                </i>
                                Cargar más ...
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    )}
                    {loading && (
                      <div className='text-center'>
                        <Loader type='TailSpin' color='#00BFFF' />
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          animation={false}
          dialogClassName='modal-90w'
          contentClassName='bg-trans'
        >
          <Modal.Body>
            <div className='ng-button-close'>
              <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={handleClose}>
                <i className='icon-2x'>
                  <FontAwesomeIcon icon={faTimes} />
                </i>
              </Button>
            </div>
            <div className='ng-arrow-left'>
              {indexFile > 0 && (
                <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={handlePrev}>
                  <i className='icon-2x'>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </i>
                </Button>
              )}
            </div>
            <div className='ng-arrow-right'>
              {indexFile < folder.length && marker !== '' && (
                <Button className='btn-trans btn-circle btn-icon btn-hover-mint' onClick={handleNext}>
                  <i className='icon-2x'>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </i>
                </Button>
              )}
            </div>
            <Row>
              <Col sm={12}>
                <div className='text-center'>
                  {currentFile && currentFile.data && (
                    <>
                      <h4 className='text-light'>{currentFile.name}</h4>
                      <img style={{ maxWidth: '100%', maxHeight: '100vh' }} src={currentFile.data} alt='preview page' />
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}
export default Library
