import React, { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

type SearchProps = {
  defaultSearch: string
  onSubmit: (tag: string) => void
}

type FormValues = {
  tag: string
}

const Search = ({ onSubmit, defaultSearch }: SearchProps): ReactElement => {
  const { register, handleSubmit, setValue } = useForm()
  const onFormSubmit = (values: FormValues) => {
    if (values.tag.trim().length) {
      onSubmit(values.tag)
    }
  }

  const handleOnBlur = (tag: string) => {
    if (tag.trim.length === 0) onSubmit(tag)
  }

  const handleClear = () => {
    setValue('tag', '')
    onSubmit('')
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='form-group'>
        <input
          type='text'
          name='tag'
          placeholder='Buscar...'
          className='form-control'
          autoComplete='off'
          ref={register}
          defaultValue={defaultSearch}
          onBlur={e => handleOnBlur(e.target.value)}
        />
        {defaultSearch !== '' && (
          <button onClick={handleClear} className='btn btn-hover-danger btn-circle'>
            <i>
              <FontAwesomeIcon icon={faTimes} />
            </i>
          </button>
        )}
      </div>
    </form>
  )
}

export default Search
