import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Col, Button, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import UserService from 'services/UserService'
import User from 'models/User'
import UserPermission from 'models/UserPermission'

import { notify } from 'components/Toast/Toast'

interface RouteParams {
  userId: string
}
const Permissions = (): ReactElement => {
  const { register, handleSubmit } = useForm()
  const { userId } = useParams<RouteParams>()
  const [user, setUser] = useState<User | null>(null)
  const history = useHistory()

  useEffect(() => {
    console.log('searching user: ', userId)
    const getUser = async () => {
      try {
        const user = await UserService.getUser(+userId)
        setUser(user)
      } catch (e) {
        console.log(e)
      }
    }
    getUser()
  }, [userId])

  const permissionExist = (resource: string, action: string) => {
    let index = -1
    if (user) {
      index = user.permissions.findIndex(item => item.resource === resource && item.action === action)
    }
    return index >= 0
  }

  const onFormSubmit = (values: { search: boolean; print: boolean; show_library: boolean }) => {
    if (user) {
      const userPermissions = []
      if (values.search) {
        userPermissions.push(
          new UserPermission({
            resource: 'Book',
            action: 'search',
          }),
        )
      }
      if (values.print) {
        userPermissions.push(
          new UserPermission({
            resource: 'Book',
            action: 'print',
          }),
        )
      }
      if (values.show_library) {
        userPermissions.push(
          new UserPermission({
            resource: 'Library',
            action: 'show',
          }),
        )
      }
      console.log(userPermissions)
      savePermissions(user.id, userPermissions)
    }
  }

  const savePermissions = async (userId: number, permissions: Array<UserPermission>) => {
    try {
      const message = await UserService.saveUserPermissions(userId, permissions)
      notify('', message)
    } catch (e) {
      console.log(e)
      notify('error', e.error)
    }
  }

  return (
    <div className='panel'>
      <Row>
        <div className='col-md-6 col-md-offset-3'>
          <div className='panel'>
            <div className='panel-heading'>
              <h3 className='panel-title'>Permisos de Usuario</h3>
            </div>
            {user && (
              <Form onSubmit={handleSubmit(onFormSubmit)} className='panel-body form-horizontal form-padding'>
                <div className='panel-body'>
                  {/*user.permissions.map(per => <div>per</div>)*/}
                  <Form.Group>
                    <h5 className='col-md-3 control-label'>Nombre</h5>
                    <Col md={9}>
                      <h5 className='form-control-static'>{user.fullName()}</h5>
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column sm='3'></Form.Label>
                    <Col md={9}>
                      <div className='checkbox'>
                        <input
                          id='search'
                          className='magic-checkbox'
                          type='checkbox'
                          name='search'
                          ref={register}
                          defaultChecked={permissionExist('Book', 'search')}
                        />
                        <label htmlFor='search'>Realizar Busquedas</label>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column sm='3'></Form.Label>
                    <Col md={9}>
                      <div className='checkbox'>
                        <input
                          id='print'
                          className='magic-checkbox'
                          type='checkbox'
                          name='print'
                          ref={register}
                          defaultChecked={permissionExist('Book', 'print')}
                        />
                        <label htmlFor='print'>Imprimir</label>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column sm='3'></Form.Label>
                    <Col md={9}>
                      <div className='checkbox'>
                        <input
                          id='show_library'
                          className='magic-checkbox'
                          type='checkbox'
                          name='show_library'
                          ref={register}
                          defaultChecked={permissionExist('Library', 'show')}
                        />
                        <label htmlFor='show_library'>Biblioteca</label>
                      </div>
                    </Col>
                  </Form.Group>
                </div>
                <div className='panel-footer'>
                  <Row>
                    <div className='col-sm-9 col-sm-offset-3'>
                      <Button variant='default' onClick={() => history.push('/users')}>
                        Cancelar
                      </Button>
                      <Button type='submit'>Actualizar Permisos</Button>
                    </div>
                  </Row>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Row>
    </div>
  )
}

export default Permissions
