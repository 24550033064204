import React, { ReactElement, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface IPage {
  index: number
  bookName: string
  pageNumber: number
  coincidencesExact: number
  coincidencesByWord: number
}

const Page = ({ index, bookName, pageNumber, coincidencesExact, coincidencesByWord }: IPage): ReactElement => {
  const history = useHistory()

  const goDetails = () => {
    history.push({
      pathname: `/details/${index}`,
      state: { pageIndex: index },
    })
  }

  return (
    <Fragment>
      <Col sm={4}>
        <div className={`panel bg-gray bord-all bord-lft-${coincidencesExact > 0 ? 'success' : 'warning'}`}>
          <div className='panel-body'>
            <div className='media bord-btm'>
              <div className='media-left'>
                <FontAwesomeIcon icon={faFileAlt} size='lg' />
              </div>
              <div className='media-body pad-btm'>
                <p className='text-lg'>Titúlo de Libro</p>
                <h4 className='mar-no text-main'>{bookName}</h4>
              </div>
              <div className='media-right text-center'>
                Página
                <h3 className='mar-no text-main'>{pageNumber}</h3>
              </div>
            </div>
            <div className='row pad-top'>
              <div className='list-group bg-trans mar-no pad-hor'>
                <p className='text-main'>
                  Coincidencias Exactas
                  <span className='pull-right text-bold'>{coincidencesExact}</span>
                </p>
                <p className='text-main'>
                  Coincidencias por palabra
                  <span className='pull-right text-bold'>{coincidencesByWord}</span>
                </p>
              </div>
            </div>
            <div className='row text-center'>
              <Col sm={12}>
                <button onClick={goDetails} className='btn btn-primary btn-labeled'>
                  <i className='btn-label'>
                    <FontAwesomeIcon icon={faInfoCircle} size='lg' />
                  </i>
                  Más detalles ...
                </button>
              </Col>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  )
}

export default Page
