import Api from 'api/Api'
import { IUserHistory } from 'models/UserHistory'
import { IPagination } from 'models/Pagination'
const resource = '/user_history'

type UserHistoryData = {
  histories: Array<IUserHistory>
  pagination: IPagination
}

export default class UserHistoryService {
  static async getAll(
    page: number,
    perPage: number,
    filters: { activity: string; user: number },
  ): Promise<UserHistoryData> {
    let url = `${resource}?page=${page}&page_size=${perPage}`
    if (filters.activity) url += `&activityTypeCode=${filters.activity}`
    if (filters.user) url += `&userId=${filters.user}`
    const { data } = await Api.get(url)

    return { histories: data.histories, pagination: data.pagination }
  }

  static async saveUserActivity(activityCode: string, resourceId: number, details: string): Promise<string> {
    const { data } = await Api.post(resource, { activityCode, resourceId, details })
    return data.message
  }
}
