import Api from 'api/Api'
import { imageProcess } from 'helpers/Image'
import BookPage from 'models/BookPage'
import { userName } from 'services/Auth'
const resource = '/books'

interface IPagesData {
  bookPages: Array<BookPage>
  pagination: Pagination
}

interface Pagination {
  total_elements: number
}

export default class BookService {
  static async search(search: string, page: number, perPage: number): Promise<IPagesData> {
    const { data } = await Api.get(`${resource}?page=${page}&page_size=${perPage}&search=${search}`)
    const bookPages = new Array<BookPage>(0)
    const pagination = data.pagination
    const currentUser = userName()
    for (const page of data.books) {
      const { imageData, imageDataPreview } = await imageProcess(
        page.data.data,
        page.coincidencesByWord,
        page.coincidencesExact,
        currentUser,
      )
      const bookPage = new BookPage({
        bookName: page.book,
        pageNumber: page.page,
        coincidencesExact: page.coincidencesExact,
        coincidencesByWord: page.coincidencesByWord,
        imageData,
        imageDataPreview,
      })
      bookPages.push(bookPage)
    }
    return { bookPages, pagination }
  }
}
