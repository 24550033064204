import React, { useState, ReactElement } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faHistory, faSearch, faJournalWhills } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import image_profile from 'assets/images/default_avatar.png'

import { currentUser, Can } from 'services/Auth'

import { Collapse } from 'react-bootstrap'

type LinkProps = {
  icon: IconDefinition
  title: string
  to: string
  activeExact?: boolean
}
const CustomLink = ({ icon, title, to, activeExact }: LinkProps) => {
  const match = useRouteMatch({
    path: to,
    exact: activeExact,
  })

  return (
    <li className={match ? 'active-link' : ''}>
      <Link to={to}>
        <i>
          <FontAwesomeIcon icon={icon} />
        </i>
        <span className='menu-title'>{title}</span>
      </Link>
    </li>
  )
}

type MenuLinkProps = {
  children: ReactElement
  to: string
  title: string
  icon: IconDefinition
  activeExact?: boolean
}

const MenuLink = ({ children, to, title, icon, activeExact }: MenuLinkProps) => {
  const [open, setOpen] = useState(false)
  const match = useRouteMatch({
    path: to,
    exact: activeExact,
  })
  /* className={active === '/users' ? 'active-sub':''}  */
  return (
    <li className={match ? 'active-sub' : ''}>
      <a onClick={() => setOpen(!open)} aria-expanded={open}>
        <i>
          <FontAwesomeIcon icon={icon} />
        </i>
        <span className='menu-title'>{title}</span>
        <i className='arrow'></i>
      </a>
      <Collapse in={open}>{children}</Collapse>
    </li>
  )
}

export default function Sidebar(): ReactElement {
  const user = currentUser()
  return (
    <nav id='mainnav-container'>
      <div id='mainnav'>
        <div id='mainnav-menu-wrap'>
          <div className='nano'>
            <div className='nano-content'>
              <div id='mainnav-profile' className='mainnav-profile'>
                <div className='profile-wrap text-center'>
                  <div className='pad-btm'>
                    <img className='img-circle img-md' src={image_profile} alt='Profile Picture' />
                  </div>
                  <p className='mnp-name'>{user ? user.shortName() : ''}</p>
                  <span className='mnp-desc'>{user ? user.email : ''}</span>
                </div>
              </div>
              <div id='mainnav-shortcut'>
                <ul className='list-unstyled shortcut-wrap'>
                  <li className='col-xs-3' data-content='My Profile'>
                    <a className='shortcut-grid' href='#'>
                      <div className='icon-wrap icon-wrap-sm icon-circle bg-mint'>
                        <i className='pli-male'></i>
                      </div>
                    </a>
                  </li>
                  <li className='col-xs-3' data-content='Messages'>
                    <a className='shortcut-grid' href='#'>
                      <div className='icon-wrap icon-wrap-sm icon-circle bg-warning'>
                        <i className='pli-speech-bubble-3'></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <ul id='mainnav-menu' className='list-group'>
                <CustomLink to='/' title='Busqueda' icon={faSearch} activeExact />
                {Can({ resource: 'Library', action: 'show' }) && (
                  <CustomLink to='/library' title='Biblioteca' icon={faJournalWhills} activeExact />
                )}
                <li className='list-divider'></li>
                {Can({ resource: 'User', action: 'manage' }) && (
                  <MenuLink to='/users' title='Usuarios' icon={faUsers}>
                    <ul>
                      <CustomLink to='/users' title='Lista de Usuarios' icon={faUsers} activeExact />
                      <CustomLink to='/users/history' title='Historial' icon={faHistory} activeExact />
                    </ul>
                  </MenuLink>
                )}
                <li className='list-divider'></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

Sidebar.propTypes = {
  //bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  //logo: PropTypes.string,
  //image: PropTypes.string,
  //logoText: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      layout: PropTypes.string,
      path: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  //open: PropTypes.bool
}
