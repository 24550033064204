import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import image_bg from 'assets/images/bg-img-3.jpg'
//import authService from 'services/Auth'
import { useForm } from 'react-hook-form'
import { notify } from 'components/Toast/Toast'

import { signin, currentUser } from 'services/Auth'

interface formValues {
  email: string
  password: string
}

const Login = (): ReactElement => {
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  const onFormSubmit = (values: formValues) => {
    login(values.email, values.password)
  }

  async function login(email: string, password: string) {
    try {
      await signin(email, password)
      history.push('/')
    } catch (e) {
      console.log('error:: ', e)
      notify('error', e.error)
    }
  }
  if (currentUser()) {
    console.log('user is autenticated')
    history.push('/')
  }
  return (
    <div id='container' className='cls-container'>
      <div id='bg-overlay' style={{ backgroundImage: `url(${image_bg})` }} className='bg-img'></div>
      <div className='cls-content'>
        <div className='cls-content-sm panel'>
          <div className='panel-body'>
            <div className='mar-ver pad-btm'>
              <h1 className='h3'>Iniciar Sesión</h1>
              <p>Ingrese sus datos para iniciar sesión en su cuenta</p>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className='form-group'>
                <input
                  type='text'
                  name='email'
                  className='form-control'
                  placeholder='Correo Electrónico'
                  autoFocus={true}
                  ref={register({ required: true })}
                />
                {errors.email && <span>errors.email.message</span>}
              </div>
              <div className='form-group'>
                <input
                  type='password'
                  name='password'
                  className='form-control'
                  placeholder='Contraseña'
                  ref={register({ required: true })}
                  autoComplete='false'
                />
                {errors.password && <span>errors.password.message</span>}
              </div>
              <div className='checkbox pad-btm text-left'>
                <input id='demo-form-checkbox' className='magic-checkbox' type='checkbox' />
                <label htmlFor='demo-form-checkbox'>Remember me</label>
              </div>

              <button className='btn btn-primary btn-lg btn-block' type='submit'>
                Iniciar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login
