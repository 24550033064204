import React, { ReactElement } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
//import { authContext }  from  'context/useAuth'
import { currentUser } from 'services/Auth'

const PrivateRoute = ({ component, ...rest }: RouteProps): ReactElement => {
  return currentUser() ? <Route {...rest} component={component} render={undefined} /> : <Redirect to='/login' />

  /*
  return (
    <Route {...rest} render={() => {
      return currentUser()
        ? component
        : <Redirect to='/login' />
    }} />
    )
  */
}

export default PrivateRoute
