//import { BehaviorSubject } from 'rxjs'
import SecureLS from 'secure-ls'
import User from 'models/User'
import Api from 'api/Api'

const ls = new SecureLS({ encodingType: 'aes' })

export const signin = async (email: string, password: string): Promise<void> => {
  const { data } = await Api.post('/auth', { email, password })
  const user = new User(data.user)
  ls.set('token', JSON.stringify(data.token))
  ls.set('currentUser', JSON.stringify(user))
}

export const signout = (): void => {
  ls.removeAll()
}

export const currentUser = (): User | null => {
  try {
    const auth = ls.get('currentUser')
    if (auth !== '') {
      return new User(JSON.parse(auth))
    }
    return null
  } catch (e) {
    console.log(e)
    return null
  }
}

export const userName = (): Array<string> => {
  const user = currentUser()
  if (user) return [user.firstName, `${user.lastName} ${user.motherLastName}`]
  return ['NO COPIAR']
}

type CanOptions = {
  resource: string
  resourceId?: number
  action: string
}

export const Can = (options: CanOptions): boolean => {
  const { resource, resourceId, action } = options
  const user = currentUser()
  if (user) {
    if (resource && action) {
      let index = -1
      if (resourceId) {
        index = user.permissions.findIndex(
          item => item.resource === resource && item.action === action && item.resourceId === resourceId,
        )
      } else {
        index = user.permissions.findIndex(item => item.resource === resource && item.action === action)
      }
      return user.role.name === 'Administrador' || index >= 0
    }
  }
  return false
}
