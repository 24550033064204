import React, { useState, useContext, ReactElement, Fragment } from 'react'
import { Can } from 'services/Auth'
import { notify } from 'components/Toast/Toast'
import { Row, Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Loader from 'react-loader-spinner'
import Page from 'views/Books/Page'
import Pagination from 'components/Pagination/Pagination'

import { BookContext, IBookContext } from 'context/bookContext'

const Home = (): ReactElement => {
  const { register, handleSubmit, errors } = useForm()
  const { search, page, perPage, totalBookPages, bookPages, updateBookPages } = useContext(BookContext) as IBookContext
  const [loading, setLoading] = useState(false)

  const onFormSubmit = async (values: { search: string }) => {
    setLoading(true)
    try {
      await updateBookPages(values.search, page, perPage)
    } catch (e) {
      console.log(e)
      if (e instanceof TypeError) notify('error', e.message)
    }
    setLoading(false)
  }

  const handlePageChange = async (newPage: number) => {
    setLoading(true)
    await updateBookPages(search, newPage, perPage)
    setLoading(false)
  }

  const handlePerPage = async (newPerPage: number) => {
    setLoading(true)
    await updateBookPages(search, page, newPerPage)
    setLoading(false)
  }

  return (
    <Fragment>
      {Can({ resource: 'Book', action: 'search' }) && (
        <div className='row pad-btm'>
          <Form onSubmit={handleSubmit(onFormSubmit)} className='col-xs-12 col-sm-10 col-sm-offset-1 pad-hor'>
            <Form.Group className={errors.search ? 'has-warning' : ''}>
              <div className='input-group mar-btm'>
                <input
                  name='search'
                  ref={register({ required: true, minLength: 5 })}
                  type='text'
                  placeholder='Buscar...'
                  className='form-control input-lg'
                />
                <span className='input-group-btn'>
                  <Button size='lg' as='input' type='submit' value='Buscar' />
                </span>
              </div>
              {errors.search && (
                <label className='control-label'>Ingresa al menos 5 caracteres para realizar una busqueda</label>
              )}
            </Form.Group>
          </Form>
        </div>
      )}

      <div className='panel'>
        <div className='panel-body'>
          {loading && (
            <div className='text-center'>
              <Loader type='TailSpin' color='#00BFFF' />
            </div>
          )}
          {search.trim().length > 0 && (
            <div className='pad-hor mar-top pad-btm'>
              <h2 className='text-thin mar-no'>
                Se encontró {totalBookPages} resultado{totalBookPages > 1 ? 's' : ''} para:
                <i className='text-info text-normal'> &ldquo;{search}&ldquo;</i>
              </h2>
            </div>
          )}
          <Row>
            {bookPages.map((page, index) => (
              <Page
                key={index}
                index={index}
                bookName={page.bookName}
                pageNumber={page.pageNumber}
                coincidencesExact={page.coincidencesExact.length}
                coincidencesByWord={page.coincidencesByWord.length}
              />
            ))}
          </Row>
          <Row>
            <Pagination
              totalRecords={totalBookPages}
              pageLimit={perPage}
              currentPage={page}
              changePage={handlePageChange}
              changePageLimit={handlePerPage}
            />
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default Home
