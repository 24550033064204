import React, { useState, ReactElement } from 'react'
import Toast from 'react-bootstrap/Toast'

let openToastFn: (type: string, message: string) => void

export default function Notifier(): ReactElement {
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [classes, setClasses] = useState('alert alert-success')
  const [type, setType] = useState('')

  const getClasses = (type: string) => {
    let class_default = 'alert alert-'
    switch (type) {
      case 'error':
        setType('ERROR')
        class_default += 'danger'
        break
      case 'info':
        setType('INFORMACIÓN')
        class_default += 'warning'
        break
      default:
        setType('ACCIÓN EXITOSA')
        class_default += 'success'
    }
    setClasses(class_default)
  }

  const showNotification = (type: string, message: string) => {
    if (!show) {
      setMsg(message)
      setType(type)
      getClasses(type)
      setShow(true)
    }
  }

  openToastFn = showNotification

  return (
    <Toast
      style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        zIndex: 11000,
      }}
      className={classes}
      onClose={() => setShow(false)}
      show={show}
      delay={5000}
      autohide
    >
      <Toast.Header>
        <strong className='mr-auto'>{type}</strong>
        <small></small>
      </Toast.Header>
      <Toast.Body>{msg}</Toast.Body>
    </Toast>
  )
}

export function notify(type: string, message: string): void {
  openToastFn(type, message)
}
