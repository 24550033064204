import React, { ReactElement /*useContext*/ } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faUnlockAlt, faBars, faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'

//import { authContext } from 'context/useAuth'
import { signout } from 'services/Auth'
type HeaderProps = {
  changeNav: () => void
}
const Header = ({ changeNav }: HeaderProps): ReactElement => {
  const history = useHistory()
  //const auth = useContext(authContext)
  const logout = () => {
    signout()
    //auth?.setCurrentUser(null)
    history.push('/')
  }
  return (
    <header id='navbar'>
      <div id='navbar-container' className='boxed'>
        {/*Brand logo & name
        ================================ */}
        <div className='navbar-header'>
          <a href='/' className='navbar-brand'>
            <i className='brand-icon text-light pad-lft'>
              <FontAwesomeIcon icon={faBookOpen} size='lg' />
            </i>
            <div className='brand-title'>
              <span className='brand-text'>Digitaliza</span>
            </div>
          </a>
        </div>
        {/*================================ 
        End brand logo & name
        Navbar Dropdown 
        ================================*/}
        <div className='navbar-content clearfix'>
          <ul className='nav navbar-top-links'>
            {/*Navigation toogle button
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <li className='tgl-menu-btn'>
              <a className='mainnav-toggle' onClick={changeNav}>
                <FontAwesomeIcon icon={faBars} />
              </a>
            </li>
          </ul>
          <ul className='nav navbar-top-links'>
            {/* User dropdown
            ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <li id='dropdown-user' className='dropdown'>
              <Dropdown>
                <Dropdown.Toggle className='text-right'>
                  <span className='ic-user pull-right'>
                    <FontAwesomeIcon icon={faUserCog} />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className='panel-default'>
                  <ul className='head-list'>
                    <li>
                      <Dropdown.Item href='#/action-1'>
                        <FontAwesomeIcon icon={faUserCog} size='lg' className='icon-fw' />
                        Perfil
                      </Dropdown.Item>
                    </li>
                    <li>
                      <Dropdown.Item onClick={logout}>
                        <FontAwesomeIcon icon={faUnlockAlt} size='lg' className='icon-fw' />
                        Cerrar Sesión
                      </Dropdown.Item>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            End user dropdown*/}
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
