import Users from 'views/Users'
import Home from 'views/Home'
import NotFound from 'views/NotFound'
import Permissions from 'views/Users/Permissions'
import UserHistory from 'views/Users/History'
import PageDetails from 'views/Books/Details'
import Library from 'views/Library'

const Routes = [
  {
    path: '/users/:userId/permissions',
    name: 'Permisos de Usuario',
    component: Permissions,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/users/history',
    name: 'Historial',
    component: UserHistory,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/users',
    name: 'Usuarios',
    component: Users,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/details/:page',
    name: 'Detalle de Página',
    component: PageDetails,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/library',
    name: 'Biblioteca',
    component: Library,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/',
    name: 'Busqueda',
    component: Home,
    layout: 'admin',
    isPrivate: true,
  },
  {
    path: '/404',
    component: NotFound,
    isPrivate: true,
  },
]

export default Routes
