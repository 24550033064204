import React, { useRef, useEffect, ReactElement } from 'react'

type CanvasProps = {
  dataImage: ImageData
}

const Canvas = ({ dataImage }: CanvasProps): ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      canvas.width = dataImage.width
      canvas.height = dataImage.height
      if (context) {
        context.putImageData(dataImage, 0, 0)
      }
    }
  }, [dataImage])

  return <canvas ref={canvasRef} />
}

export default Canvas
