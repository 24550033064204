import Api from 'api/Api'
import { IFolder, IFile } from 'models/Book'
const resource = '/library'

export default class LibraryService {
  static async getBuckets(): Promise<Array<string>> {
    const { data } = await Api.get(resource)
    return data.bucketsName
  }

  static async fileManager(bucket: string, pathName: string, marker: string): Promise<IFolder> {
    let url = `${resource}/list_by_bucket?bucket=${bucket}`
    if (pathName !== '') url += `&currentFolder=${pathName}&marker=${marker}`
    const { data } = await Api.get(url)
    const files = data.files.map((file: IFile) => {
      if (file.type === 'File' && file.data) {
        let base64 = 'data:image/jpg;base64,'
        base64 += Buffer.from(file.data).toString('base64')
        file['data'] = base64
      }
      return file
    })
    return { pathName: data.pathName, files, nextMarker: data.nextMarker }
  }

  static async getFile(folder: string, fileName: string): Promise<string> {
    const { data } = await Api.get(`${resource}/show_file?folder=${folder}&fileName=${fileName}`)
    console.log(data.imageData.data)
    let base64 = 'data:image/jpg;base64,'
    base64 += Buffer.from(data.imageData.data).toString('base64')
    return base64
  }
}
