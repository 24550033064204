import React, { useState, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { useForm, FieldErrors } from 'react-hook-form'
import { Modal, Row, Col, Button, Form } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import { joiResolver } from '@hookform/resolvers/joi'
import 'select2-component/dist/select2.min.css'
import { schema_create, schema_edit } from 'validations/UserSchema'
import User from 'models/User'
import Role, { DefaultRoles } from 'models/Role'

type UserProps = {
  user: User
  onSubmit: (user: User) => void
  onShow: boolean
  onClose: () => void
}

type FormDataProps = {
  user: User
  errors: FieldErrors
  register: () => void
}

const UserFormData = ({ user, errors, register }: FormDataProps) => {
  const [role, setRole] = useState(user.role.id)
  const [blocked, setBlocked] = useState(user.blocked)

  const handleChangeBlocked = () => setBlocked(!blocked)

  return (
    <>
      <Row>
        <Col sm={12}>
          <Form.Group className={errors.firstName ? 'has-error' : ''}>
            <Form.Label>Nombre(s)</Form.Label>
            <Form.Control name='firstName' ref={register} defaultValue={user.firstName} />
            <Form.Text className='text-danger'>{errors.firstName?.message}</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className={errors.lastName ? 'has-error' : ''}>
            <Form.Label>Apellido Paterno</Form.Label>
            <Form.Control name='lastName' ref={register} defaultValue={user.lastName} />
            <Form.Text className='text-danger'>{errors.lastName?.message}</Form.Text>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className={errors.motherLastName ? 'has-error' : ''}>
            <Form.Label>Apellido Materno</Form.Label>
            <Form.Control name='motherLastName' ref={register} defaultValue={user.motherLastName} />
            <Form.Text className='text-danger'>{errors.motherLastName?.message}</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className={errors.email ? 'has-error' : ''}>
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control type='email' name='email' ref={register} defaultValue={user.email} />
            <Form.Text className='text-danger'>{errors.email?.message}</Form.Text>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className={errors.password ? 'has-error' : ''}>
            <Form.Label>Contraseña</Form.Label>
            <Form.Control type='password' name='password' ref={register} autoComplete='none' />
            <Form.Text className='text-danger'>{errors.password?.message}</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Rol de Usuario</Form.Label>
            <Form.Control type='hidden' name='role' value={role} ref={register} />
            <Select2 data={DefaultRoles} value={role} update={value => setRole(+value)}></Select2>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Bloquear Usuario</Form.Label>
            <div className='checkbox' onChange={handleChangeBlocked}>
              <input
                id='blocked_1'
                name='blocked'
                className='magic-checkbox'
                type='checkbox'
                ref={register}
                defaultChecked={blocked}
              />
              <label htmlFor='blocked_1'>{blocked ? 'Si' : 'No'}</label>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

const UserForm = ({ user, onSubmit, onShow, onClose }: UserProps): ReactElement => {
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(user.id ? schema_edit : schema_create),
  })

  type FormValues = {
    role: number
    email: string
    password?: string
    firstName: string
    lastName: string
    motherLastName: string
    blocked: boolean
  }

  const onFormSubmit = (values: FormValues) => {
    const userRole = new Role({ id: values.role })
    user.role = userRole
    user.email = values.email
    if (values.password) user.password = values.password
    user.firstName = values.firstName
    user.lastName = values.lastName
    user.motherLastName = values.motherLastName
    user.blocked = values.blocked
    onSubmit(user)
  }

  return (
    <Modal show={onShow} onHide={onClose} backdrop='static' keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{user.id ? 'Editar Usuario' : 'Crear Usuario'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <UserFormData user={user} errors={errors} register={register} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClose}>
            Cerrar
          </Button>
          <Button as='input' type='submit' value='Guardar' />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

UserForm.propTypes = {
  user: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default UserForm
