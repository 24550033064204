interface IUserPermission {
  resource?: string
  resourceId?: number
  action?: string
  userId?: number
  createdBy?: number
}

export default class UserPermission {
  resource
  resourceId
  action
  userId
  createdBy
  constructor(properties: IUserPermission = {}) {
    this.resource = properties.resource || ''
    this.resourceId = properties.resourceId || 0
    this.action = properties.action || ''
    this.userId = properties.userId || 0
    this.createdBy = properties.createdBy || 0
  }
  asJSON(): IUserPermission {
    return {
      resource: this.resource,
      resourceId: this.resourceId,
      action: this.action,
      userId: this.userId,
      createdBy: this.createdBy,
    }
  }
}
