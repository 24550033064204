import React, { useState, useEffect, ReactElement } from 'react'
import { format } from 'date-fns'
import { Row, Col } from 'react-bootstrap'
import { Select2 } from 'select2-react-component'
import Loader from 'react-loader-spinner'
import { notify } from 'components/Toast/Toast'
import UserHistoryService from 'services/UserHistoryService'
import UserService from 'services/UserService'
import ActivityTypeService from 'services/ActivityTypeService'
import Pagination from 'components/Pagination/Pagination'
import NiftyTable from 'components/Table/NiftyTable'
import { IUserHistory } from 'models/UserHistory'

const UserHistory = (): ReactElement => {
  const [userHistory, setUserHistory] = useState(new Array<IUserHistory>(0))
  const [loading, setLoading] = useState(false)

  // filters
  const [activities, setActivities] = useState([{ value: '', label: 'Todos' }])
  const [users, setUsers] = useState([{ value: 0, label: 'Todos' }])
  const [activity, setActivity] = useState('')
  const [user, setUser] = useState(0)

  //pagination
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalUserHistory, setTotalUserHistory] = useState(0)

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(() => {
    getHistory(page, perPage)
  }, [activity, user, page, perPage])

  const getFilters = async () => {
    try {
      const { users } = await UserService.getUsersByName()
      const dataUser = users.map((item: { id: number; name: string }) => {
        return { value: item.id, label: item.name }
      })
      dataUser.unshift({ value: 0, label: 'Todos' })
      setUsers(dataUser)
      const { activityTypes } = await ActivityTypeService.list()
      setActivities(activityTypes)
    } catch (e) {
      notify('error', e.error)
      console.log(e)
    }
  }

  const getHistory = async (page: number, perPage: number) => {
    setLoading(true)
    try {
      const { histories, pagination } = await UserHistoryService.getAll(page, perPage, { activity, user })
      setPage(+pagination.current_page)
      setPerPage(perPage)
      setTotalUserHistory(+pagination.total_elements)
      setUserHistory(histories)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  const handleChangeActivity = (activity: string) => {
    setActivity(activity)
    setPage(1)
  }

  const handleChangeUser = (user: number) => {
    setUser(user)
    setPage(1)
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handlePerPage = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const getBody = () => {
    return (
      <>
        {userHistory.map((row, key) => {
          return (
            <tr key={key}>
              <td>{row.user.firstName}</td>
              <td>{row.activityType.description}</td>
              <td>{row.info}</td>
              <td>{format(new Date(row.createdAt), 'Pp')}</td>
            </tr>
          )
        })}
      </>
    )
  }

  return (
    <div className='panel'>
      <div className='panel-body'>
        <div className='pad-btm form-inline'>
          {loading && (
            <div className='text-center'>
              <Loader type='TailSpin' color='#00BFFF' />
            </div>
          )}
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}>
              <div className='form-group mar-rgt col-sm-5'>
                <span>Actividad: </span>
                <Select2
                  data={activities}
                  value={activity}
                  update={value => handleChangeActivity(value + '')}
                ></Select2>
              </div>
              <div className='form-group col-sm-6'>
                <span>Usuario: </span>
                <Select2 data={users} value={user} update={value => handleChangeUser(+value)}></Select2>
              </div>
            </Col>
          </Row>
        </div>
        <NiftyTable headers={['Usuario', 'Actividad', 'Información', 'Fecha']} dataBody={getBody()} />
        <Pagination
          totalRecords={totalUserHistory}
          pageLimit={perPage}
          currentPage={page}
          changePage={handlePageChange}
          changePageLimit={handlePerPage}
        />
      </div>
    </div>
  )
}

export default UserHistory
