import React from 'react'
import ReactDOM from 'react-dom'

// assets
import 'assets/css/bootstrap.min.css'
import 'assets/css/nifty.min.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import App from 'App'

ReactDOM.render(<App />, document.getElementById('root'))
