import React, { useState, ReactNode, ReactElement } from 'react'
import BookService from 'services/BookService'
import BookPage from 'models/BookPage'

export interface IBookContext {
  search: string
  bookPages: Array<BookPage>
  page: number
  perPage: number
  totalBookPages: number
  updateBookPages: (searchTag: string, page: number, perPage: number) => void
}

type BookProps = {
  children: ReactNode
}

export const BookContext = React.createContext<IBookContext | null>(null)

const BookProvider = ({ children }: BookProps): ReactElement => {
  const [bookPages, setBookPages] = useState(new Array<BookPage>(0))
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [totalBookPages, setTotalBookPages] = useState(0)

  const updateBookPages = async (searchTag: string, page: number, perPage: number) => {
    try {
      const data = await BookService.search(searchTag, page, perPage)
      setTotalBookPages(data.pagination.total_elements)
      setBookPages(data.bookPages)
      setSearch(searchTag)
      setPage(page)
      setPerPage(perPage)
    } catch (e) {
      console.log('error in BookContext: ', e)
    }
  }

  return (
    <BookContext.Provider value={{ search, page, perPage, totalBookPages, bookPages, updateBookPages }}>
      {children}
    </BookContext.Provider>
  )
}

export default BookProvider
