import React, { ReactElement } from 'react'
import { Switch, useRouteMatch, useLocation, Redirect } from 'react-router-dom'

import Routes from 'config/routes'
import PrivateRoute from 'components/PrivateRoute'
import BookProvider from 'context/bookContext'

const Main = (): ReactElement => {
  const location = useLocation()
  const isNotFound = useRouteMatch('/404')

  const switchRoutes = () => (
    <Switch>
      {Routes.map((route, key) => {
        if (route.isPrivate) {
          return <PrivateRoute key={key} path={route.path} exact component={route.component} />
        }
        return null
      })}
      <Redirect to='/404' />
    </Switch>
  )

  const makeBrand = () => {
    const finded = Routes.find(item => item.path === location.pathname)
    if (finded && finded.name) return finded.name
    return ''
  }

  return (
    <div id='content-container'>
      <div id='page-head'>
        {isNotFound ? (
          <div className='text-center cls-content'>
            <h1 className='error-code text-info'>404</h1>
          </div>
        ) : (
          <div id='page-title'>
            <h1 className='page-header text-overflow'>{makeBrand()}</h1>
          </div>
        )}
      </div>

      <div id='page-content'>
        <BookProvider>{switchRoutes()}</BookProvider>
      </div>
    </div>
  )
}

export default Main
