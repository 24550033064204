import React, { ReactElement } from 'react'
//import { ProvideAuth } from 'context/useAuth'
import { BrowserRouter as Router } from 'react-router-dom'
import Notifier from 'components/Toast/Toast'
import BaseLayout from 'views/layouts/BaseLayout'

const App = (): ReactElement => (
  <Router>
    <Notifier />
    <BaseLayout />
  </Router>
)

export default App
