import React, { Fragment, FC, ReactNode } from 'react'

const Footer: FC<ReactNode> = () => (
  <Fragment>
    <footer id='footer'>
      <div className='hide-fixed pull-right pad-rgt'></div>
      <p className='pad-lft'>2021 | Karimnot Inc.</p>
    </footer>
    <button className='scroll-top btn'>
      <i className='pci-chevron chevron-up'></i>
    </button>
  </Fragment>
)

export default Footer
