import { IRect } from 'models/BookPage'

type ImagesData = {
  imageData: ImageData | null
  imageDataPreview: ImageData | null
}

export const imageProcess = async (
  src: Buffer,
  rects: Array<IRect>,
  rectsExact: Array<IRect>,
  userName: Array<string>,
): Promise<ImagesData> => {
  const image = new Image()
  let base64 = 'data:image/jpg;base64,'
  base64 += Buffer.from(src).toString('base64')
  image.src = base64
  let imageData: ImageData | null = null
  let imageDataPreview: ImageData | null = null
  await image.decode()
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const context = canvas.getContext('2d')
  if (context) {
    context.drawImage(image, 0, 0)
    imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    // use for rect fills
    context.fillStyle = 'rgba(204, 143, 0, 0.4)'
    for (const rect of rects) {
      context.fillRect(
        rect.Left * image.width,
        rect.Top * image.height,
        rect.Width * image.width,
        rect.Height * image.height,
      )
    }
    context.globalAlpha = 1.0
    context.fillStyle = 'rgba(139, 195, 74, 0.4)'
    for (const rect of rectsExact) {
      context.fillRect(
        rect.Left * image.width,
        rect.Top * image.height,
        rect.Width * image.width,
        rect.Height * image.height,
      )
    }
    context.globalAlpha = 1.0
    context.translate(canvas.width / 2, canvas.height / 2)
    context.rotate(-Math.PI / 4)
    let textSize = canvas.width / 10
    if (canvas.width > canvas.height) textSize = canvas.height / 12
    context.font = textSize + 'pt Times New Roman'
    context.fillStyle = 'rgba(176, 32, 1, 0.4)'
    context.textAlign = 'center'
    userName.forEach((item: string, index: number) => {
      context.fillText(item, 0, index * 200, canvas.width * 0.8)
    })
    context.restore()
    imageDataPreview = context.getImageData(0, 0, canvas.width, canvas.height)
  }
  return { imageData, imageDataPreview }
}

const splitFrames = async (imageData: ImageData, divWidth: number): Promise<Array<Array<ImageData>>> => {
  const pieces = []
  if (imageData) {
    const imageRisezed = await resizeImage(imageData, divWidth)
    const tileDim = 50

    const imgW = imageRisezed.width //600//239
    const imgH = imageRisezed.height //500//211

    const tileCountX = ~~(imgW / tileDim)
    const tileCountY = ~~(imgH / tileDim)
    let totalX = tileCountX
    let totalY = tileCountY
    const tileResX = imgW % tileDim
    const tileResY = imgH % tileDim

    if (tileResX > 0) totalX += 1
    if (tileResY > 0) totalY += 1

    for (let y = 0; y < totalY; y++) {
      const row = []
      for (let x = 0; x < totalX; x++) {
        if (y === tileCountY) {
          // llego a final de fila
          // 50 * rest
          //row.push(getPiece(imageRisezed, x, y, tileDim, tileResY, imgW))
          row.push(getPiece(imageRisezed, x, y, tileDim, tileResY, imgW))
        } else if (x === tileCountX) {
          // resto * 50
          //row.push(getPiece(imageRisezed, x, y, tileResX, tileDim, imgW))
          row.push(getPiece(imageRisezed, y, x, tileResX, tileDim, imgW))
        } else {
          // default 50 * 50 dim
          row.push(getPiece(imageRisezed, x, y, tileDim, tileDim, imgW))
        }
      }
      pieces.push(row)
    }
  }
  return pieces
}

const getPiece = (
  imageData: ImageData,
  startX: number,
  startY: number,
  width: number,
  height: number,
  canvasWidth: number,
): ImageData => {
  const arrayBuffer = new ArrayBuffer(width * height * 4)
  const pixels = new Uint8ClampedArray(arrayBuffer)

  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      const index = (y * width + x) * 4
      const index2 = ((y + startY * width) * canvasWidth + (x + startX * height)) * 4
      if (imageData) {
        pixels[index] = imageData.data[index2]
        pixels[index + 1] = imageData.data[index2 + 1]
        pixels[index + 2] = imageData.data[index2 + 2]
        pixels[index + 3] = imageData.data[index2 + 3]
      }
    }
  }
  const splitImage = new ImageData(pixels, width, height)
  return splitImage
}

const resizeImage = async (imageData: ImageData, divWidth: number): Promise<ImageData> => {
  let resizeWidth = 0
  let resizeHeight = 0

  const windowWidth = divWidth
  const windowHeight = ~~(window.innerHeight * 0.8)
  const imageRatio = imageData.height / imageData.width
  const pageRatio = windowHeight / windowWidth

  if (pageRatio > imageRatio) {
    resizeHeight = windowHeight
    resizeWidth = windowHeight / imageRatio
  } else {
    resizeHeight = windowWidth * imageRatio //imageData.height //
    resizeWidth = windowWidth // imageData.width //
  }
  resizeWidth = resizeWidth - (resizeWidth % 50)
  resizeHeight = resizeHeight - (resizeHeight % 50)

  const canvas = document.createElement('canvas')
  canvas.width = imageData.width
  canvas.height = imageData.height
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.putImageData(imageData, 0, 0)
    const canvasRezise = document.createElement('canvas')
    canvasRezise.width = resizeWidth
    canvasRezise.height = resizeHeight
    const ctxRezise = canvasRezise.getContext('2d')
    if (ctxRezise) {
      ctxRezise.scale(resizeWidth / imageData.width, resizeHeight / imageData.height)
      ctxRezise.drawImage(canvas, 0, 0)
      const imageDataResize = ctxRezise.getImageData(0, 0, resizeWidth, resizeHeight)
      return imageDataResize
    }
  }
  return imageData
}

export default splitFrames
