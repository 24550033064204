interface RoleProperties {
  id?: number
  name?: string
}
export default class Role {
  id
  name
  constructor(properties: RoleProperties = {}) {
    this.id = properties.id || 2
    this.name = roles[this.id - 1] || 'Usuario'
  }
}

const roles = ['Administrador', 'Usuario']

export const DefaultRoles = roles.map((item, index) => {
  return { value: index + 1, label: item }
})
