import React, { useState, useEffect, useRef, ReactElement, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Canvas from './Canvas'
import PrintPage from './PrintPage'
import { BookContext, IBookContext } from 'context/bookContext'
import BookPage from 'models/BookPage'
import splitFrames from 'helpers/Image'
import 'assets/css/image_preview.min.css'
import { currentUser } from 'services/Auth'

type RowProps = {
  piecesRow: Array<ImageData>
}

type DetailParams = {
  page: string
}

const Detail = (): ReactElement => {
  const history = useHistory()
  const { search, bookPages } = useContext(BookContext) as IBookContext
  const { page } = useParams<DetailParams>()
  const [pieces, setPieces] = useState(new Array<Array<ImageData>>(0))
  const [showModal, setShowModal] = useState(false)
  const [book, setBook] = useState<null | BookPage>(null)

  const printRef = useRef<HTMLDivElement>(null)
  const previewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const pageFinded = bookPages[+page]
    if (pageFinded && pageFinded.imageDataPreview) {
      setBook(pageFinded)
      getPieces(pageFinded.imageDataPreview)
    }
  }, [page])

  const RowPiece = ({ piecesRow }: RowProps) => {
    return (
      <div className='no-mar-between'>
        {piecesRow.map((piece, key) => (
          <Canvas key={key} dataImage={piece} />
        ))}
      </div>
    )
  }

  const getPieces = async (data: ImageData) => {
    if (previewRef && previewRef.current) {
      const divWidth = ~~(previewRef.current.offsetWidth * 0.8)
      const piecestmp = await splitFrames(data, divWidth)
      setPieces(piecestmp)
    }
  }

  const handleShow = async () => {
    setShowModal(true)
  }

  const printPermission = () => {
    const user = currentUser()
    if (user) {
      const index = user.permissions.findIndex(item => item.resource === 'Book' && item.action === 'print')
      return user.role.name === 'Administrador' || index >= 0
    }
    return false
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <div className='panel'>
      <div className='panel-body'>
        <Button className='btn-mint' onClick={history.goBack}>
          Regresar
        </Button>
        <div className='pad-btm form-inline'>
          <div className='row'>
            <div className='col-sm-6 table-toolbar-left'>
              <h4>
                Frase Buscada:
                <span className='text-bold'>{search}</span>
              </h4>
            </div>
            <div className='col-sm-6 table-toolbar-right'>
              <Button className='btn-labeled mar-rgt' onClick={handleShow}>
                <i className='btn-label'>
                  <FontAwesomeIcon icon={faSearch} size='lg' />
                </i>
                Visualizar
              </Button>
              {printPermission() && book && (
                <PrintPage imageData={book.imageData} page={book.pageNumber} bookName={book.bookName} />
              )}
            </div>
          </div>
        </div>

        <div style={{ display: 'none' }}>
          <div ref={printRef}>{book && book.imageData && <Canvas dataImage={book.imageData} />}</div>
        </div>

        <div className='table-responsive text-center' ref={previewRef}>
          {pieces.map((piece, index) => (
            <RowPiece key={index} piecesRow={piece} />
          ))}
        </div>

        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
          animation={false}
          dialogClassName='modal-90w'
          contentClassName='bg-trans'
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <div className='text-right pad-top'>
                  <Button variant='warning' className='btn-icon btn-circle' onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimesCircle} size='lg' />
                  </Button>
                </div>
              </Col>
            </Row>
            <div className='text-center'>
              {pieces.map((piece, index) => (
                <RowPiece key={index} piecesRow={piece} />
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default Detail
